import React, { useState, useEffect } from 'react';
import { View, SafeAreaView, ScrollView, Modal } from 'react-native';
import ViewPortProvider from '../../hooks/ViewPortProvider';
import useViewport from '../../hooks/useViewport';
import THEME from '../../config/theme';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import {
  doc,
  addDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  getDocs,
  orderBy,
  query,
  collection,
  onSnapshot,
  where,
} from 'firebase/firestore';
import { firestore } from '../../services/firebase';
import Header from '../../components/Header';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { ImageUpload } from '../../components/ImagePicker';
import { Provider, ActivityIndicator } from 'react-native-paper';
import { firestoreAutoId } from '../../utils';
import Constants from 'expo-constants';
import { EditVideo } from '../../components/EditVideo';
import { AlertBox } from '../../components/AlertBox';
import EditCardInformationModal from './EditCardInformation';
import ViewVideo from './ViewVideo';
import ViewFlatList from './ViewFlatList';
import FlatListView from './FlatListView';
import ViewImage from './ViewImage';
import EditOrder from './EditOrder';
import { useUserPlan, useUser } from '../../queries/userQuery';
import { hasSchedule } from '../../utils/hasSchedule';
import { useCustomTheme } from '../../contexts/useCustomTheme';
import GenericModal from '../../components/Modals/GenericModal';
import { useRemoteConfigContext } from '../../contexts/useRemoteConfigContext';

const auth = getAuth();

const { guruUserToken } = Constants.manifest.extra;

export function ClickCard({ route }) {
  const { cardId, categoryId, firstPlanPurchase } = route.params;
  const config = useRemoteConfigContext();
  const hasForm = config.hasForm;

  const navigation = useNavigation();
  const [selectedId, setSelectedId] = useState(null);
  const [card, setCard] = useState();
  const [videos, setVideos] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState();
  const [user, setUser] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { customColor, customInfo } = useCustomTheme();

  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [showEditOrderModal, setShowEditOrderModal] = useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [showEditVideoModal, setShowEditVideoModal] = useState(false);
  const [showVideoBackgroundUploadModal, setShowVideoBackgroundUploadModal] = useState(false);
  const [showCardBackgroundUploadModal, setShowCardBackgroundUploadModal] = useState(false);
  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false);

  const [showOkAlert, setShowOkAlert] = useState(false);
  const [okAlertMessage, setOkAlertMessage] = useState('');

  const [plans, setPlans] = useState([]);
  const [plansCard, setPlansCard] = useState([]);
  const [productIdsCard, setProductIdsCard] = useState([]);
  const [courses, setCourses] = useState([]);
  const [products, setProducts] = useState([]);
  const [videoUnsubscribes, setVideoUnsubscribes] = useState([]);
  const [isVideoModalVisible, setVideoModalVisible] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [releaseDate, setReleaseDate] = useState('');

  const { planType } = user || {};
  const { img, backgroundImage } = card || {};
  const cardBackgroundImageUrl = backgroundImage?.url || img;

  const { data: infoUserPlan } = useUserPlan();
  const { data: userData } = useUser();

  const getUser = async (user) => {
    const docRef = doc(firestore, 'users', user);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUser(() => ({ id: docSnap.id, ...docSnap.data() }));
    }
  };

  const fetchUserAndData = async (uid) => {
    await getUser(uid);
    fetchData();
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        fetchUserAndData(user.uid);
      } else {
        signInAnonymously(auth)
          .then(() => {
            setIsLoading(false);
            // Login anônimo realizado com sucesso.
          })
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const hasToOpenFormEmbed = hasForm && customInfo?.formEmbed && customInfo?.iosUrl && customInfo?.androidUrl && !userData?.filledForm && !auth.currentUser.isAnonymous

  const updateVideoOrder = async (updatedVideos) => {
    try {
      setIsSaving(true);
      for (const index in updatedVideos) {
        const newDocId = updatedVideos[index].docId;
        const videoRef = doc(
          firestore,
          'categories',
          categoryId,
          'cards',
          cardId,
          'videos',
          newDocId
        );
        // Needed to reverse logic due to previous implementation that top is the highest number.
        await updateDoc(videoRef, { order: +index });
      }
    } catch (error) {
      console.error('Failed to update video order: ', error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const cardId = route.params.cardId;
    const categoryId = route.params.categoryId;
    const videosRef = doc(firestore, 'categories', categoryId, 'cards', cardId);

    const unsubscribe = onSnapshot(videosRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const cardData = docSnapshot.data();
        setPlansCard(cardData);
      } else {
        setPlansCard(null);
      }
    });
    return () => unsubscribe();
  }, [route.params.cardId, route.params.categoryId]);

  const handleOnDeleteVideoClick = (video) => {
    setSelectedVideoId(video.docId);
    setShowDeleteVideoModal(true);
  };

  const handleOnDeleteVideoConfirm = async () => {
    await deleteDoc(
      doc(
        firestore,
        'categories',
        categoryId,
        'cards',
        cardId,
        'videos',
        selectedVideoId
      )
    );
  };

  const handleOnDeleteCardConfirm = async () => {
    await deleteDoc(doc(firestore, 'categories', categoryId, 'cards', cardId));
    // Rever sistema de roteamento
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'Painel' }],
      })
    );
  };

  const fetchProducts = async (productIds, data) => {
    const productsCollection = collection(firestore, 'products');
    const q = query(
      productsCollection,
      where('marketplace', 'in', ['Guru Digital', 'PAGARME', 'IAP'])
    );
    const querySnapshot = await getDocs(q);
    const products = [];

    querySnapshot.forEach((doc) => {
      const productData = doc.data();
      const productId = productData?.productId;
      const isSelected = productIds?.includes(productId);
      products.push({
        ...productData,
        selected: isSelected,
      });
    });

    setProducts(products);

    let productsCard = [];
    if (data.productIds) {
      for (let i = 0; i < data.productIds.length; i++) {
        const productId = data.productIds[i];
        // Encontrar o objeto correspondente em 'products' com base no 'productId'
        const product = products.find(
          (product) => product.productId === productId
        );
        if (product) {
          // Adicionar o título do produto ao 'productsCard'
          productsCard.push({
            productId: productId,
            title: product.name, // Adiciona o título do produto
            marketplace: product.marketplace
          });
        }
      }
      setProductIdsCard(productsCard);
    }
  };

  const fetchData = async () => {
    const docRef = doc(firestore, 'categories', categoryId, 'cards', cardId);
    const docSnap = await getDoc(docRef);

    const data = { docId: docSnap.id, ...docSnap.data() };

    fetchProducts(data?.productIds, data);

    let plans = [];
    if (data.plans) {
      for (let i = 0; i < data.plans.length; i++) {
        plans.push(data.plans[i]);
      }
    }

    let plansCard = [];
    if (data.plans) {
      for (let i = 0; i < data.plans.length; i++) {
        plansCard.push(data.plans[i]);
      }
    }

    const getPlans = async (plans) => {
      const plansSnap = await getDocs(
        query(collection(firestore, `plans`), orderBy('order', 'asc'))
      );

      let plansCollection = [];

      plansSnap.forEach((doc) => {
        const plan = doc.data();
        if (plan.isPlanFrequency == false || plan.isPlanFrequency == null) {
          if (plan.monthlyPriceId) {
            plans.push({
              id: doc.id,
              title: `${plan.title} Mensal`,
              selected: false,
              productId: plan.productIds.monthly,
            }),
              plansCollection.push({
                id: doc.id,
                title: `${plan.title} Mensal`,
                selected: false,
                productId: plan.productIds.monthly,
              });
          }
          if (plan.quarterlyPriceId || plan.quarterlyAtSightPriceId) {
            plans.push({
              id: doc.id,
              title: `${plan.title} Trimestral`,
              selected: false,
              productId: plan.productIds.quarterly,
            }),
              plansCollection.push({
                id: doc.id,
                title: `${plan.title} Trimestral`,
                selected: false,
                productId: plan.productIds.quarterly,
              });
          }
          if (plan.semiannualPriceId || plan.semiannualAtSightPriceId) {
            plans.push({
              id: doc.id,
              title: `${plan.title} Semestral`,
              selected: false,
              productId: plan.productIds.semiannual,
            }),
              plansCollection.push({
                id: doc.id,
                title: `${plan.title} Semestral`,
                selected: false,
                productId: plan.productIds.semiannual,
              });
          }
          if (plan.yearlyPriceId || plan.yearlyAtSightPriceId) {
            plans.push({
              id: doc.id,
              title: `${plan.title} Anual`,
              selected: false,
              productId: plan.productIds.yearly,
            }),
              plansCollection.push({
                id: doc.id,
                title: `${plan.title} Anual`,
                selected: false,
                productId: plan.productIds.yearly,
              });
          }
        }
      });
      const duplicateIds = plans
        .map((v) => v.productId)
        .filter((v, i, vIds) => vIds.indexOf(v) !== i);
      plans.filter((obj) => duplicateIds.includes(obj.productId));
      const duplicates2 = plans.filter((obj) => obj.selected === true);
      function getDifference(array1, array2) {
        return array1.filter((object1) => {
          return !array2.some((object2) => {
            return object1.productId === object2.productId;
          });
        });
      }

      let difference = getDifference(plansCollection, plansCard);

      for (let i = 0; i < duplicates2.length; i++) {
        difference.push(duplicates2[i]);
      }

      setPlans(difference);
    };

    let courses = [];
    if (docSnap.data().coursesCard) {
      for (let i = 0; i < docSnap.data().coursesCard.length; i++) {
        courses.push(docSnap.data().coursesCard[i]);
      }
    }

    let coursesCard = [];
    if (docSnap.data().coursesCard) {
      for (let i = 0; i < docSnap.data().coursesCard.length; i++) {
        coursesCard.push(docSnap.data().coursesCard[i]);
      }
    }
    const getCourses = async (courses) => {
      const response = await getDocs(collection(firestore, `courses`));

      let coursesCollection = [];
      response.forEach((doc) => {
        courses.push({
          id: doc.id,
          title: doc.data().title,
          productId: doc.data().productId,
        });
        coursesCollection.push({
          id: doc.id,
          title: doc.data().title,
          productId: doc.data().productId,
        });
      });

      const duplicateIds = courses
        .map((v) => v.productId)
        .filter((v, i, vIds) => vIds.indexOf(v) !== i);
      courses.filter((obj) => duplicateIds.includes(obj.productId));
      const duplicates2 = courses.filter((obj) => obj.selected === true);

      function getDifference(array1, array2) {
        return array1.filter((object1) => {
          return !array2.some((object2) => {
            return object1.productId === object2.productId;
          });
        });
      }

      let difference = getDifference(coursesCollection, coursesCard);

      for (let i = 0; i < duplicates2.length; i++) {
        difference.push(duplicates2[i]);
      }

      setCourses(difference);
    };
    const getCoursesPromise = getCourses(courses);
    const getPlansPromise = getPlans(plans);
    await Promise.all([getCoursesPromise, getPlansPromise]);

    setIsLoading(false);
  };

  useEffect(() => {
    const run = async () => {
      const videosRef = collection(
        firestore,
        'categories',
        categoryId,
        'cards',
        cardId,
        'videos'
      );
      const videosSnap = await getDocs(videosRef);

      if (!videosSnap.docs.length) return;
      videosSnap.docs.map((doc) => subscribeVideo(doc.ref));
    };
    run();
    return () => videoUnsubscribes.forEach((unsubscribe) => unsubscribe());
  }, []);

  // Load card data and receive updates.
  useEffect(() => {
    let unsubscribe = null;

    const run = async () => {
      const docRef = doc(firestore, 'categories', categoryId, 'cards', cardId);
      unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (!docSnap.exists()) return;
        const card = { id: docSnap.id, ...docSnap.data() };
        setCard(card);
      });
    };
    run();

    return () => unsubscribe && unsubscribe();
  }, [categoryId, cardId]);

  const subscribeVideo = (videoRef) => {
    const unsubscribe = onSnapshot(videoRef, (docSnap) => {
      setVideos((prevState) => {
        const filteredVideos = prevState.filter(
          (video) => video.docId !== docSnap.id
        );

        // Document has been deleted
        if (!docSnap.exists()) {
          return filteredVideos;
        }

        const data = docSnap.data();
        const descriptionVideo = docSnap.data().description
          ? docSnap.data().description
          : [''];
        const doc = {
          docId: docSnap.id,
          ...data,
          description: descriptionVideo,
        };

        return [...filteredVideos, doc].sort((a, b) => a.order - b.order);
      });
    });
    setVideoUnsubscribes((prevState) => [...prevState, unsubscribe]);
  };

  const addVideo = async (type = 'content') => {
    try {
      const numOfVideosWithSameType =
        videos.filter((video) => video.type === type).length + 1;

      const title =
        (type === 'content' &&
          `Nome do Vídeo ou Áudio #${numOfVideosWithSameType}`) ||
        (type === 'live' &&
          `Nome da Aula ao Vivo #${numOfVideosWithSameType}`) ||
        '';

      const newVideo = {
        title,
        order: videos[0] ? videos[0]?.order - 1 : videos.length,
        type,
        videoId: `${firestoreAutoId()}${type}`, // This will be deprecated soon
      };

      const videosRef = collection(
        firestore,
        'categories',
        categoryId,
        'cards',
        cardId,
        'videos'
      );

      const docRef = await addDoc(videosRef, newVideo);

      subscribeVideo(docRef);

      const alertMessage =
        (type === 'content' && `Novo vídeo/áudio adicionado com sucesso!`) ||
        (type === 'live' && `"Nova aula ao vivo adicionada com sucesso!"`) ||
        '';

      setOkAlertMessage(alertMessage);
      setShowOkAlert(true);
    } catch (error) {
      console.error;
    }
  };

  const updateCard = async (data) => {
    try {
      setIsSaving(true);
      const cardsRef = doc(
        firestore,
        'categories',
        categoryId,
        'cards',
        cardId
      );
      await updateDoc(cardsRef, data);
    } catch (error) {
      console.error('Failed to update card.', error);
    } finally {
      setIsSaving(false);
    }
  };

  const updateVideo = async (data) => {
    if (data.isScheduling === true) {
      data.schedulingDays = 0;
    }

    if (data.isSchedulingNewStudents === true) {
      data.selectedDate = {};
    }

    if (data.isSchedulingNewStudents === false && data.isScheduling === false) {
      data.selectedDate = {};
      data.schedulingDays = 0;
    }

    try {
      setIsSaving(true);
      const videoRef = doc(
        firestore,
        'categories',
        categoryId,
        'cards',
        cardId,
        'videos',
        selectedVideoAdmin.docId
      );
      await updateDoc(videoRef, data);
    } catch (error) {
      console.error('Failed to update video.', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditVideoClick = (video) => {
    setSelectedVideoId(video.docId);
    setShowEditVideoModal(true);
  };

  const handleEditVideoBackgroundClick = (video) => {
    setSelectedVideoId(video.docId);
    setShowVideoBackgroundUploadModal(true);
  };

  // Isso aqui da pra melhorar, mas vai ficar assim por enquanto.
  const selectedVideoAdmin = videos.find(
    (video) => video.docId === selectedVideoId
  );

  const handleSelectedVideoChange = (video) => {
    if (!user?.isAdmin) {
      if (hasToOpenFormEmbed) {
        setIsOpenModal(true);
        return;
      }
      if (hasSchedule(video, infoUserPlan)) {
        return calculatesSchedule(video);
      }
    }

    navigation.navigate("Aula", {
      cardId: cardId,
      categoryId: categoryId,
      firstPlanPurchase: firstPlanPurchase,
      selectedVideo: video.docId,
    });
  };

  const calculatesSchedule = (video) => {
    const {
      schedulingDays,
      selectedDate,
      isSchedulingNewStudents,
      isScheduling,
    } = video;

    let newDate;

    if (isScheduling) {
      const videoScheduledDateMillis = selectedDate?.seconds * 1000;
      if (videoScheduledDateMillis) {
        newDate = new Date(videoScheduledDateMillis);
      }
    }

    if (isSchedulingNewStudents) {
      const created = infoUserPlan.created;

      const timestampLength = String(created).length;
      let timestampInMilliseconds;
      if (timestampLength === 10) {
        timestampInMilliseconds = created * 1000;
      }
      if (timestampLength === 13) {
        timestampInMilliseconds = created;
      }
      if (typeof created === 'string' && created.includes('T')) {
        timestampInMilliseconds = new Date(created);
      }

      const createdDate = new Date(timestampInMilliseconds);

      const parsedDaysToAdd = parseInt(schedulingDays);
      if (isNaN(parsedDaysToAdd)) {
        throw new Error('Invalid number of days to add.');
      }

      newDate = new Date(createdDate);
      newDate.setDate(createdDate.getDate() + parsedDaysToAdd);

      const day = String(newDate.getDate()).padStart(2, '0');
      const month = String(newDate.getMonth() + 1).padStart(2, '0');
      const year = newDate.getFullYear();

      setReleaseDate(`${day}/${month}/${year}`);
    }

    if (newDate) {
      const day = String(newDate.getDate()).padStart(2, '0');
      const month = String(newDate.getMonth() + 1).padStart(2, '0');
      const year = newDate.getFullYear();

      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        const releaseDate = `${day}/${month}/${year}`;
        setReleaseDate(releaseDate);
      }

      const todayDateMillis = new Date().getTime();
      const isAfterScheduleDate = todayDateMillis >= newDate.getTime();

      // if isAfterScheduleDate is true or no releaseDate (manual access), content is available
      if (isAfterScheduleDate || releaseDate === null) {
        navigation.navigate("Aula", {
          cardId: cardId,
          categoryId: categoryId,
          firstPlanPurchase: firstPlanPurchase,
          selectedVideo: video.docId,
        });
      } else {
        setVideoModalVisible(true);
      }
    } else {
      console.error('newDate is not set');
    }
  };

  const handleConfirm = () => {
    setIsOpenModal(false);
  };

  if (isLoading) {
    return (
      <ActivityIndicator
        style={{
          flex: 1,
          backgroundColor:
            customColor && customColor.background?.app
              ? customColor.background.app
              : THEME.COLORS.BACKGROUND_MAIN,
          justifyContent: 'center',
        }}
        color={
          customColor && customColor.primary
            ? customColor.primary
            : THEME.COLORS.PRIMARY_800
        }
      />
    );
  }

  const OutsideView = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <Provider>
        <View
          style={{
            alignSelf: 'center',
            width: '100%',
            height: (width - 32) / 1.78,
            marginBottom: '1rem',
            paddingHorizontal: '2%',
          }}
        >
          <ViewVideo
            user={user}
            card={card}
            navigation={navigation}
            firstPlanPurchase={firstPlanPurchase}
            guruUserToken={guruUserToken}
          />
        </View>
        <ViewFlatList
          card={card}
          videos={videos}
          user={user}
          onVideoListClick={handleSelectedVideoChange}
          plansCard={plansCard}
          productsCard={productIdsCard}
        />
        {!user?.isAdmin && releaseDate && (
          <AlertBox
            title={'Conteúdo disponível em breve'}
            message={`Este conteúdo estará disponível para você em ${releaseDate}`}
            visible={isVideoModalVisible}
            leftButtonFunction={() => setVideoModalVisible(false)}
            onClose={() => setVideoModalVisible(false)}
            leftButton={'OK'}
          />
        )}
      </Provider>
    ) : (
      <Provider>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            paddingHorizontal: '2%',
            paddingVertical: '2rem',
          }}
        >
          <View
            style={{
              alignSelf: 'center',
              height: (width * 0.7 - 32) / 1.78,
              width: '70%',
            }}
          >
            <ViewVideo
              user={user}
              card={card}
              navigation={navigation}
              firstPlanPurchase={firstPlanPurchase}
              guruUserToken={guruUserToken}
            />
          </View>
          <ScrollView
            showsHorizontalScrollIndicator={true}
            style={{
              alignSelf: 'left',
              width: '30%',
              height: (width * 0.7 - 32) / 1.78,
              paddingLeft: '1rem',
            }}
          >
            <ViewFlatList
              card={card}
              videos={videos}
              user={user}
              onVideoListClick={handleSelectedVideoChange}
              releaseDate={releaseDate}
              plansCard={plansCard}
              productsCard={productIdsCard}
            />

            {!user?.isAdmin && releaseDate && (
              <AlertBox
                title={'Conteúdo disponível em breve'}
                message={`Este conteúdo estará disponível para você em ${releaseDate}`}
                visible={isVideoModalVisible}
                leftButtonFunction={() => setVideoModalVisible(false)}
                onClose={() => setVideoModalVisible(false)}
                leftButton={'OK'}
              />
            )}
          </ScrollView>
        </View>
      </Provider>
    );
  };

  const OutsideViewAdmin = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <>
        <View
          style={{
            alignSelf: 'center',
            width: '100%',
            height: (width - 32) / 1.78,
            marginBottom: '1rem',
            paddingHorizontal: '2%',
          }}
        >
          <ViewImage
            img={cardBackgroundImageUrl}
            showModal={() => setShowCardBackgroundUploadModal(true)}
          />
        </View>
        <FlatListView
          videos={videos}
          user={user}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          onDeleteVideoClick={handleOnDeleteVideoClick}
          setVideoIndexToBeDeleted={{}}
          onAddVideoClick={addVideo}
          cardInformation={card}
          onDeleteCardClick={() => setShowDeleteCardModal(true)}
          onEditCardInformationClick={() => setShowEditCardModal(true)}
          onEditOrderClick={() => setShowEditOrderModal(true)}
          onEditVideoClick={handleEditVideoClick}
          onEditVideoBackgroundClick={handleEditVideoBackgroundClick}
          onVideoListClick={handleSelectedVideoChange}
        />
      </>
    ) : (
      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: '2%',
          flex: 1,
        }}
      >
        <View
          style={{
            height: (width * 0.7 - 32) / 1.78,
            width: '70%',
          }}
        >
          <ViewImage
            img={cardBackgroundImageUrl}
            showModal={() => setShowCardBackgroundUploadModal(true)}
          />
        </View>
        <FlatListView
          videos={videos}
          user={user}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          onDeleteVideoClick={handleOnDeleteVideoClick}
          setVideoIndexToBeDeleted={{}}
          onAddVideoClick={addVideo}
          cardInformation={card}
          onDeleteCardClick={() => setShowDeleteCardModal(true)}
          onEditCardInformationClick={() => setShowEditCardModal(true)}
          onEditOrderClick={() => setShowEditOrderModal(true)}
          onEditVideoClick={handleEditVideoClick}
          onEditVideoBackgroundClick={handleEditVideoBackgroundClick}
          onVideoListClick={handleSelectedVideoChange}
        />
      </View>
    );
  };

  return user?.isAdmin ? (
    <Provider>
      <ViewPortProvider>
        <SafeAreaView
          style={{
            backgroundColor:
              customColor && customColor.background?.app
                ? customColor.background.app
                : THEME.COLORS.BACKGROUND_MAIN,
            flexGrow: 1,
            alignContent: 'flex-start',
          }}
        >
          <Header
            onPress={() => {
              navigation.dispatch(
                CommonActions.reset({
                  routes: [{ name: 'Painel' }],
                })
              );
            }}
            user={user}
          />
          <OutsideViewAdmin />
          <Modal animationType="fade" visible={showEditCardModal}>
            <EditCardInformationModal
              isSaving={isSaving}
              onClose={() => setShowEditCardModal(false)}
              onSave={updateCard}
              card={card}
              plans={plans}
              courses={courses}
              products={products}
            />
          </Modal>
          <Modal animationType="fade" visible={showCardBackgroundUploadModal}>
            <ImageUpload
              onClose={() => setShowCardBackgroundUploadModal(false)}
              metadata={{
                databasePath: `categories/${categoryId}/cards/${cardId}`,
                databasePropName: 'backgroundImage',
              }}
            />
          </Modal>
          <Modal animationType="fade" visible={showVideoBackgroundUploadModal}>
            <ImageUpload
              onClose={() => setShowVideoBackgroundUploadModal(false)}
              metadata={{
                databasePath: `categories/${categoryId}/cards/${cardId}/videos/${selectedVideoId}`,
                databasePropName: 'backgroundImage',
              }}
            />
          </Modal>
          <Modal animationType="fade" visible={showEditVideoModal}>
            <EditVideo
              categoryId={categoryId}
              cardId={cardId}
              videoId={selectedVideoId}
              planType={planType}
              maybeRenderImage={'EditVideo'}
              video={selectedVideoAdmin}
              onSave={updateVideo}
              onClose={() => setShowEditVideoModal(false)}
              user={user}
              plans={plansCard}
              products={productIdsCard}
            />
          </Modal>
          <Modal animationType="fade" visible={showEditOrderModal}>
            <EditOrder
              videos={videos}
              isSaving={isSaving}
              onClose={() => setShowEditOrderModal(false)}
              onSave={updateVideoOrder}
            />
          </Modal>
          <AlertBox
            title={
              'Você TEM CERTEZA que deseja excluir esse vídeo ou aula ao vivo?'
            }
            message={
              'Ao excluir, todos os conteúdos correspondentes a este vídeo ou aula ao vivo serão removidos permanentemente (isso inclui vídeo, áudio, PDF e imagem de capa)'
            }
            visible={showDeleteVideoModal}
            leftButton={'Sim'}
            rightButton={'Não'}
            rightButtonFunction={() => setShowDeleteVideoModal(false)}
            leftButtonFunction={handleOnDeleteVideoConfirm}
            onClose={() => setShowDeleteVideoModal(false)}
          />
          <AlertBox
            message={okAlertMessage}
            visible={showOkAlert}
            leftButtonFunction={() => setShowOkAlert(false)}
            onClose={() => setShowOkAlert(false)}
            leftButton={'OK'}
          />
          <AlertBox
            title={'Você TEM CERTEZA que deseja excluir esse módulo inteiro?'}
            message={
              'Ao excluir, todos os conteúdos correspondentes a este módulo serão removidos permanentemente (isso inclui todos os vídeos, áudios, PDFs e imagens de capa)'
            }
            visible={showDeleteCardModal}
            leftButton={'Sim'}
            rightButton={'Não'}
            rightButtonFunction={() => setShowDeleteCardModal(false)}
            leftButtonFunction={handleOnDeleteCardConfirm}
            onClose={() => setShowDeleteCardModal(false)}
          />
          {isLoading && (
            <ActivityIndicator
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              size="large"
              color={
                customColor && customColor.primary
                  ? customColor.primary
                  : THEME.COLORS.PRIMARY_800
              }
            />
          )}
        </SafeAreaView>
      </ViewPortProvider>
    </Provider>
  ) : (
    <ViewPortProvider>
      <SafeAreaView
        style={{
          backgroundColor:
            customColor && customColor.background?.app
              ? customColor.background.app
              : THEME.COLORS.BACKGROUND_MAIN, flexGrow: 1
        }}
      >
        <Header
          onPress={() => {
            navigation.dispatch(
              CommonActions.reset({
                routes: [{ name: 'Conteudo' }],
              })
            );
          }}
          user={user}
        />
        <GenericModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          onConfirm={handleConfirm}
          title="Conclua seu cadastro"
          message="Para visualizar os conteúdos, é necessário concluir o cadastro pelo aplicativo baixado. Acesse abaixo:"
          appleLink={customInfo?.iosUrl}
          androidLink={customInfo?.androidUrl}
        />
        <OutsideView />
      </SafeAreaView>
    </ViewPortProvider>
  );
}
